<template>
  <div id='privacy-policy-screen' class="base-page">
    <transition
      mode="in-out"
      enter-active-class="animate__animated animate__fadeIn animate__faster"
      leave-active-class="animate__animated animate__fadeOut animate__faster"
    >
      <div class="container container-hungarospa">
        <h1 class="title">{{ $t('pages.privacyPolicy.title') }}</h1>
        <div class="content-wrapper" v-html="content" />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'PrivacyPolicyScreen',
  props: {},
  components: {},
  data: () => ({
    content: undefined,
  }),
  created() {
    this.getPrivacyPolicy().then((resp) => { this.content = resp.data; });
  },
  computed: {},
  methods: {
    ...mapActions({
      getPrivacyPolicy: 'settings/getPrivacyPolicy',
    }),
  },
};
</script>

<style lang='scss' scoped>
</style>
